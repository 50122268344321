<!-- @format -->

<template>
  <div class="filmwelt-social">
    <div class="filmwelt-social__wrapper">
      <a
        v-if="facebook"
        class="filmwelt-social__facebook"
        :href="$config.facebook.link"
        target="_blank"
      >
        <facebook-box />
      </a>
      <a
        v-if="youtube"
        class="filmwelt-social__youtube"
        href="https://www.youtube.com/channel/UCNWqErBUP3ve1vfnf-gTaDA"
        target="_blank"
      >
        <youtube />
      </a>
      <a v-if="instagram" class="filmwelt-social__instagram" href="">
        <instagram />
      </a>
    </div>
  </div>
</template>

<script>
  import FacebookBox from 'vue-material-design-icons/Facebook.vue'
  import Youtube from 'vue-material-design-icons/Youtube.vue'
  import Instagram from 'vue-material-design-icons/Instagram.vue'
  export default {
    components: {
      FacebookBox,
      Youtube,
      Instagram,
    },
    props: {
      facebook: {
        type: Boolean,
        default: false,
      },
      youtube: {
        type: Boolean,
        default: false,
      },
      instagram: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .filmwelt-social {
    $self: &;
    &__wrapper {
      #{$self}__facebook,
      #{$self}__youtube,
      #{$self}__instagram {
        position: relative;
        text-transform: uppercase;
        height: $header-height;
        line-height: $header-height;
        padding-right: 2px;
        color: $header-link;
        font-weight: $header-fontweight;
        cursor: pointer;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 22px;
        $max_font: $header-fontsize + 10;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        &:hover {
          svg {
            fill: $header-link-hover;
          }
        }
        svg {
          fill: $header-link;
        }
      }
    }
  }
</style>
